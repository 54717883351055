export const setURL = url => ({
    type: 'SET_URL',
    url
})
  
export const toggleLightbox = () => ({
    type: 'TOGGLE_LIGHTBOX'
})

export const setImgLoaded = loaded => ({
    type: 'SET_IMG_LOAD',
    loaded
})