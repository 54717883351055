const initialState = {
  url: 'placeholder.png',
  open: false,
  loaded: false
}

const lightboxReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_URL':
      return (
        {
          ...state,
          url: action.url,
          loaded: false
        }
      )
    case 'TOGGLE_LIGHTBOX':
      return (
        {
          ...state,
          open: !state.open,
          url: state.open ? "placeholder.png" 
          : state.url
        }
      )
    case 'SET_IMG_LOAD':
      return (
        {
          ...state,
          loaded: action.loaded
        }
      )
    default:
      return state
  }
}

export default lightboxReducer