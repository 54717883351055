import React from "react";
import {pageListener, scrollStyles} from "../services/maxs-scroll-magic";
import ImageDemos from "./Images";


const websiteData = require("../data/websites.json");

class SlidingContent extends React.Component {
    constructor(){
        super();
        this.wrapper = React.createRef();
        this.bottoms = React.createRef();

        this.cards = [];
    }

    componentDidMount(){

        const cardHeight = 0.85;
        const buffer = 0.1;
        const cardCount = 4;
        let styleObject = [];

        for(let i=0; i<cardCount-1; i++){
            let start = 1+(i*cardHeight);
            let end = start + 0.85;
            styleObject.push({pageStart:start, pageEnd:end, valueStart: 1, valueEnd: 0.9, prop: `scale_${i}`});
        }
        

        scrollStyles(styleObject,
        val => {
            for(let i=0; i<cardCount-1; i++){
                this.cards[i].style.transform = `scale(${val[`scale_${i}`]})`;
            }
        });

       
        pageListener((page) => {
            let cardPass = 0;

            for(let i=0; i<cardCount; i++){
                if (page > (1+((i*cardHeight)-buffer))) cardPass = Math.min(i, 2);
            }

            this.bottoms.current.setAttribute('data-cards-passed', cardPass);
        });

    }

    render(){
        return (
        <div className="card-wrapper" ref={this.wrapper}>
            {this.renderCards()}
            <div className="bottom-bits" ref={this.bottoms}>
                <div className="bottom"></div>
                <div className="bottom"></div>
            </div>
        </div>
    )};

    renderCards(){
        let cards = [];
        const sites = websiteData['websites'];
        sites.forEach((site, i)=> {
            let last = (i === sites.length-1 );
            cards.push(
                <div className={last ? 'card last' : 'card'} key={i} ref={this.addCardRef.bind(this)}>
                    <ImageDemos images={site.images}/>
                    <div className="site-desc">
                        <h1>{site.role}<small>{site.location}</small></h1>
                        <p>{site.desc}</p>
                        <div className="mobile-gradient"></div>
                        <div className="logos">
                            {site.techStack.map(e => <img key={e} alt={e + " Logo"} src={`/images/tech-logos/${e}.png`} />)}
                        </div>
                    </div>
                </div>
            );
        });
        return cards;
    }


    addCardRef(node){
        if(!node) return;
        else this.cards = [...this.cards, node];
    }

}


export default SlidingContent;