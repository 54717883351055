import React from "react";

class SlideTitle extends React.PureComponent {
    constructor(props){
        super(props);
        this.rails = React.createRef();
        this.state = {
            children: []
        };
    }

    componentDidMount(){
        this.setState({
            children: this.rails.current.childNodes
        });
    }

    render(){
        const styles = {
            transform: `translateY(-${this.props.current*80}px)`,
            width: this.state.children.length > 0 ? 
                `${this.state.children[this.props.current].offsetWidth}px`
                : 'auto'
        };

        const titles = this.props.titles.map(title => 
            <span key={title}>{title}</span>
        );

        return (
            <b ref={this.rails} style={styles}>{titles}</b>
        );
    };
}

export default SlideTitle;