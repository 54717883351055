import React from 'react';

import Main from "./wrappers/Main";

class App extends React.Component {
  
  state = {
    temperature: undefined,
    city: undefined,
    country: undefined,
    humidity: undefined,
    description: undefined,
    error: undefined,
    toggle: false,
  }

  render(){
    return (
        <Main />
    );
  }
}

export default App;