import React from "react";

export default function Backgrounds(){
    return (
        <div className="bands">
            <div className="band-1"></div>
            <div className="band-2"></div>
            <div className="band-3"></div>
            <div className="band-4"></div>
            <div className="band-5"></div>
        </div>
    )
}
