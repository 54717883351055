let ticking = false;

let lastScrollY = window.scrollY;
let intViewportHeight = window.innerHeight;

let pagePercent = ()=>(lastScrollY / intViewportHeight);

const throttledScroll = new CustomEvent('throttledScroll', {detail: {page: pagePercent}});
const throttledResize = new CustomEvent('throttledResize');


function onScroll (e) {

  	// Store the scroll value for laterz.
	lastScrollY = window.scrollY;

	if(!ticking){

		window.requestAnimationFrame(function() {
			window.dispatchEvent(throttledScroll);
			ticking = false;
		});

		ticking = true;

	}
}

let resizing;
function onResize (e){
	intViewportHeight = window.innerHeight;
	clearTimeout(resizing)
	resizing = setTimeout(()=>{
		requestAnimationFrame(()=>{
			window.dispatchEvent(throttledResize);
		})
	},100);
}

window.addEventListener('scroll', onScroll);
window.onload = onScroll();
window.addEventListener('resize', onResize);


export function scrollStyles(options, callback){
	let calculatedRatio=[];
	let returnVals={};
	options['rounding'] = options['rounding'] || 1000;

	window.addEventListener('throttledScroll', (e)=>{
		options.forEach((v,i) =>{
			calculatedRatio[i]= (v.pageEnd - v.pageStart) / (v.valueEnd - v.valueStart);
			let currentPage = e.detail.page();
			if(currentPage < v.pageStart) returnVals[v.prop] = v.valueStart;
			else if (currentPage > v.pageEnd) returnVals[v.prop] = v.valueEnd;
			else if(currentPage >= v.pageStart && currentPage <= v.pageEnd){
				let relativePage = currentPage - v.pageStart;
				let preRound = v.valueStart+(relativePage / calculatedRatio[i]);
				returnVals[v.prop] = 
					Math.round(preRound * options['rounding']) / options['rounding'];
			}
		});
		callback(returnVals);
	});
}

export function pageListener(callback){
	window.addEventListener('throttledScroll', (e)=>{
		callback(e.detail.page(), lastScrollY);
	});
}

export function resizeListener(callback){
	window.addEventListener('throttledResize', (e)=>{
		callback();
	});
}

// {
// 	pageStart: "",
// 	pageEnd: "",
// 	valueStart: "",
// 	valueEnd: "",
// }