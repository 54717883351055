import '../src/services/maxs-scroll-magic.js';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux'
import { createStore } from 'redux'
import lightbox from './reducers'

import './App.scss';
import App from './App';

const store = createStore(lightbox)

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, 
    document.getElementById('root')
);