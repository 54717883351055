import React from "react";
import {scrollStyles, pageListener, resizeListener} from "../services/maxs-scroll-magic";
import SlideTitle from "./SlideTitle"

export default class Titles extends React.PureComponent{
    constructor(props){
        super(props);

        this.title = React.createRef();
        this.titleWrapper = React.createRef();

        this.workplaces = ['\u00A0', 'Drive/CarAdvice', 'Solar Link Australia','King Kong','Freelance'];
        this.names = ['Dalton', '@'];
        this.state = {
            currentWorkplace: 0,
            currentName: 0,
            isMobile: false
        };

    }

    componentDidMount(){
        const el = this.title.current;
        const wrapper = this.titleWrapper.current;

        function resizeHandler(){
            wrapper.style.marginTop = `${Math.round(window.innerHeight/2.5)}px`;
            checkMobile();
        }

        var checkMobile = () => {
            let isMobileCurrently = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 768);
            this.setState(() => ({isMobile: isMobileCurrently}))
        }

        scrollStyles([
            {pageStart:0, pageEnd:1, valueStart: 1, valueEnd: 0.5, prop: "scale"},
            {pageStart:0, pageEnd:0.5, valueStart: window.innerHeight/2.5, valueEnd: 10, prop: "translate"}],
        val => {
            let roundedScale = Math.round(val.scale*1000)/1000;
            el.style.transform = `scale(${roundedScale})`;
        });

        let workplaceNum=0;
        let cardHeight = 0.85;
        let buffer = 0.3;
        let cardCount = 4;

        pageListener(page => {
            workplaceNum=0;

            if(!this.state.isMobile){
                for(let i=0; i<=cardCount; i++){
                    if (page > (1+(i*cardHeight))-buffer){
                        workplaceNum = (i + 1) % (cardCount + 1);
                    }
                }
            }

            this.setState(prevState => {
                if (prevState.currentWorkplace === workplaceNum) return null;
                return {
                    currentWorkplace: workplaceNum,
                    currentName: Math.min(workplaceNum, 1),
                }
            });
        });

        resizeListener(resizeHandler);

        resizeHandler();
    }

    render(){
        return (
        <hgroup ref={this.titleWrapper}>
            <h1 ref={this.title}>
                Max&nbsp;
                <SlideTitle 
                    titles={this.names} 
                    current={this.state.currentName}
                />

                {!this.state.isMobile &&
                    <span>&nbsp;
                        <SlideTitle 
                            titles={this.workplaces} 
                            current={this.state.currentWorkplace} 
                        />
                    </span>
                }
            </h1>
        </hgroup>
    )};
}
