import React from "react";
import {scrollStyles} from "../services/maxs-scroll-magic";

class Footer extends React.Component {
    constructor(props){
        super(props);
        this.footer = React.createRef();
    }

    componentDidMount(){
        const el = this.footer.current;
        scrollStyles([
            {pageStart:4.5, pageEnd:4.9, valueStart: 1, valueEnd: 1.2, prop: "scale"}],
        val => {
            el.style.transform = `scale(${val.scale})`;
        });
    }

    render(){
        return (
            <div ref={this.footer} className="footer">
                <h2>Contact</h2>
                <a href="mailto:contact@maxdalton.com.au">contact@maxdalton.com.au</a>
                <a href="tel:0423308747">0423 308 747</a>
            </div>
        );
    };
}

export default Footer;