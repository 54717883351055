import React from 'react';
import Titles from '../components/Titles';
import Backgrounds from '../components/Backgrounds';
import SlidingContent from '../components/SlidingContent';
import Footer from '../components/Footer';
import Lightbox from '../components/Lightbox';

export default class Main extends React.Component {
  render(){
    return (
      <div className="main-wrapper">
        <Backgrounds />
        <Titles />
        <SlidingContent />
        <Footer />
        <Lightbox />
      </div>
    );
  }
}