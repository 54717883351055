import React from "react";
import { connect } from 'react-redux'
import { setURL, toggleLightbox } from '../actions'
  
function ImageDemos(props){
    return (
        <div className="site-image">
            {renderImgs(props)}
        </div>
    );
}

function renderImgs(props){

    function clickHandler(url){
        props.dispatch(setURL(url))
        props.dispatch(toggleLightbox())
    }

    return props['images'].map(
        url => (
            <div 
                key={url}
                className="image-holder" 
                style={{backgroundImage: `url(/images/sites_thumbs/${url.replace('.png', '.jpg')})`}}
                onClick={(e) => clickHandler(url)}
            >

            </div>
        )
    );
}

export default connect()(ImageDemos);