import React from "react";
import { connect } from 'react-redux'
import { toggleLightbox, setImgLoaded } from "../actions"

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const mapStateToProps = state => ({url: state.url, open:state.open, loaded: state.loaded});

function Lightbox(props){
    
    function handleClick(e){
        e.preventDefault();
        e.stopPropagation();
        props.dispatch(toggleLightbox());
    }

    if(!props.loaded){
        const downloadingImage = new Image();
        downloadingImage.onload = function(){
            props.dispatch(setImgLoaded(true));
        };
        downloadingImage.src = `/images/sites/${props.url}`;
    }

    const targetElement = document.querySelector(".lightbox");

    if(props.open){
        disableBodyScroll(targetElement);
    }else{
        enableBodyScroll(targetElement);
    }

    return (
        <div 
            className={'lightbox ' + (props.open ? 'open' : 'closed')}
            onClick={(e) => handleClick(e)}
        >
            <img 
                alt=""
                className={props.loaded ? "" : "loading" }
                src={props.loaded ? `/images/sites/${props.url}` : `/images/sites_thumbs/${props.url}`.replace('.png', '.jpg')}
            >
            </img>

            {!props.loaded && <div className="animated-loader"></div>}

            <div
                className='close-button'
                onClick={(e) => handleClick(e)}
            >
                &times;
            </div>
        </div>
    )
}


export default connect(mapStateToProps)(Lightbox);